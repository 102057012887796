// eslint-disable-next-line import/no-unresolved
import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from './http';

export const getSidebarItemCountData = createAsyncThunk(
  'static/sidebar/count',
  async ({ enqueueSnackbar, userType }) => {
    try {
      const response = await get(`/static/dashboard/counts?user_type=${userType && userType}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getDashboardData = createAsyncThunk(
  'static/dashboard',
  async ({ enqueueSnackbar }) => {
    try {
      const response = await get('/dashboard/');
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

// partner charts
export const getPartnerEarningChartData = createAsyncThunk(
  'earning/admin',
  async ({ enqueueSnackbar, year }) => {
    try {
      const response = await get(`earning/admin/graph/monthly?&year=${year || ''}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getPartnerSalesChartData = createAsyncThunk(
  'sale/admin',
  async ({ enqueueSnackbar, year }) => {
    try {
      const response = await get(`sale/admin/graph/monthly?&year=${year || ''}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
export const getPartnerBalanceChartData = createAsyncThunk(
  'earning/admin/increment',
  async ({ enqueueSnackbar, year }) => {
    try {
      const response = await get(`/earning/admin/increment/graph/monthly?&year=${year || ''}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

// school course progression graph
export const getProgressionGraphData = createAsyncThunk(
  'school-course/school-user/graph/data',
  async ({ enqueueSnackbar }) => {
    try {
      const response = await get('/school-course/school-user/graph/data');
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
