import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createPlan = createAsyncThunk(
  'subscription-plan/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/subscription/plans/`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Plan Created Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllPlans = createAsyncThunk(
  'subscription-plan/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/subscription/plans/?page=${page ?? 1}&search=${search ?? ''}&pageSize=${limit || 10}`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getPlanDetails = createAsyncThunk(
  'subscription-plan/single',
  async ({ planId, dispatch }) => {
    try {
      const response = await get(`/subscription/plans/${planId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updatePlanDetails = createAsyncThunk(
  'subscription-plan/update',
  async ({ state, planId, dispatch, handleClose }) => {
    try {
      const URL = `/subscription/plans/${planId}/`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Plan Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deletePlan = createAsyncThunk(
  'subscription-plan/delete',
  async ({ planId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/subscription/plans/${planId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Plan Deleted Successfully' }));
        return planId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
