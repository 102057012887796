// ----------------------------------------------------------------------

const ROOTS = {
  DASHBOARD: '/dashboard',
  AUTH: '/auth',
};

// ----------------------------------------------------------------------

export const paths = {
  public: {
    login: '/login',
  },
  // AUTH
  auth: {
    logout: `${ROOTS.AUTH}/logout`,
  },
  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}/app`,
    onBoarding: '/on-boarding',
    profile: `${ROOTS.DASHBOARD}/profile`,
    course: `${ROOTS.DASHBOARD}/course`,
    course_view: (id) => `${ROOTS.DASHBOARD}/course/${id}`,
    module: `${ROOTS.DASHBOARD}/module`,
    module_view: (id) => `${ROOTS.DASHBOARD}/module/${id}`,
    category: `${ROOTS.DASHBOARD}/category`,
    plan: `${ROOTS.DASHBOARD}/plan`,
    quiz: `${ROOTS.DASHBOARD}/quiz`,
    user: `${ROOTS.DASHBOARD}/user`,
    // transaction: `${ROOTS.DASHBOARD}/transaction`,
    course_purchase: `${ROOTS.DASHBOARD}/course-purchase`,
    plan_purchase: `${ROOTS.DASHBOARD}/plan-purchase`,
    settings: `${ROOTS.DASHBOARD}/settings`,
  },
};

export const AuthRoles = {
  admin: 'Admin',
  partner: 'Partner',
  school: 'School',
  academy: 'Student',
};
