import { createSlice } from '@reduxjs/toolkit';

import { getAllPlanPurchases } from '../api/planPurchase';

export const planPurchaseSlice = createSlice({
  name: 'planPurchase',
  initialState: {
    loading: false,
    error: {},
    // staff
    planPurchaseList: {},
    staffDropList: [],
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [getAllPlanPurchases.pending]: (state) => {
      state.loading = true;
    },
    [getAllPlanPurchases.fulfilled]: (state, action) => {
      state.loading = false;
      state.planPurchaseList = action.payload;
      state.error = {};
    },
    [getAllPlanPurchases.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = planPurchaseSlice.actions;

export default planPurchaseSlice.reducer;
