import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getDashboardData } from 'src/server/api/dashboard';

import { useSettingsContext } from 'src/components/settings';
import DashboardLoading from 'src/components/loading/DashboardLoading';

import DashboardContent from 'src/sections/app/admin';

// ----------------------------------------------------------------------

export default function DashboardAdminApp() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { dashLoading, dashboardData } = useSelector((state) => ({
    dashLoading: state.dashboard.loading,
    dashboardData: state.dashboard.dashboardData,
  }));

  useEffect(() => {
    const credentials = {
      enqueueSnackbar,
    };
    dispatch(getDashboardData(credentials));
  }, [dispatch, enqueueSnackbar]);

  const settings = useSettingsContext();

  return (
    <>
      <MetaHelmet title="Dashboard" />

      <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{ mt: 4 }}>
        {!dashLoading ? <DashboardContent dashboardData={dashboardData} /> : <DashboardLoading />}
      </Container>
    </>
  );
}
