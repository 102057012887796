import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { AuthRoles } from 'src/routes/routes/paths';

import LoginAdmin from 'src/assets/other/khaf.png';

import { SplashScreen } from 'src/components/loading-screen';

const AuthLayout = lazy(() => import('src/layouts/auth/authLayout'));
const LogoutPage = lazy(() => import('src/pages/Common/Auth/LogoutPage'));
const LoginAdminPage = lazy(() => import('src/pages/Common/Auth/Login'));

const CompactLayout = lazy(() => import('src/layouts/compact'));
const AllCourses = lazy(() => import('src/pages/Common/Course'));
const AllCoursePurchases = lazy(() => import('src/pages/Common/CoursePurchase'));
const AllPlanPurchases = lazy(() => import('src/pages/Common/PlanPurchase'));
const AllTransactions = lazy(() => import('src/pages/Common/Transaction'));
const AllCategories = lazy(() => import('src/pages/Common/Category'));
const SettingsPage = lazy(() => import('src/pages/Admin/settings'));
const AllUsers = lazy(() => import('src/pages/Common/User'));
const AllModules = lazy(() => import('src/pages/Common/Module'));
const AllSubscriptionPlans = lazy(() => import('src/pages/Common/SubscriptionPlan'));
const ModuleView = lazy(() => import('src/pages/Common/Module/SingleView'));
const AllQuizes = lazy(() => import('src/pages/Common/Quiz'));
const CourseView = lazy(() => import('src/pages/Common/Course/SingleView'));

const Payment = lazy(() => import('src/pages/Common/Payment/payment'));
const Success = lazy(() => import('src/sections/payment/success'));
const Failed = lazy(() => import('src/sections/payment/failed'));
const Page500 = lazy(() => import('src/pages/Common/Static/500'));
const Page403 = lazy(() => import('src/pages/Common/Static/403'));
const Page404 = lazy(() => import('src/pages/Common/Static/404'));

const publicRoutes = [
  {
    path: 'payment/:trn_token',
    element: <Payment />,
  },
  {
    path: 'payment-success',
    element: <Success />,
  },
  {
    path: 'payment-failed',
    element: <Failed />,
  },
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'login',
        element: (
          <AuthLayout image={LoginAdmin} title="Authenticate as an administrator" userRole="admin">
            <LoginAdminPage role={AuthRoles.admin} />
          </AuthLayout>
        ),
      },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];

const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),

    children: [
      {
        path: 'logout',
        element: <LogoutPage />,
      },
      { path: '404', element: <Page404 isInner /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

// super admin
const adminRoutes = [
  {
    path: 'course',
    element: <AllCourses />,
  },
  {
    path: 'course/:id',
    element: <CourseView />,
  },
  {
    path: 'module',
    element: <AllModules />,
  },
  {
    path: 'module/:id',
    element: <ModuleView />,
  },
  {
    path: 'category',
    element: <AllCategories />,
  },
  {
    path: 'plan',
    element: <AllSubscriptionPlans />,
  },
  {
    path: 'quiz',
    element: <AllQuizes />,
  },
  {
    path: 'user',
    element: <AllUsers />,
  },
  {
    path: 'transaction',
    element: <AllTransactions />,
  },
  {
    path: 'course-purchase',
    element: <AllCoursePurchases />,
  },
  {
    path: 'plan-purchase',
    element: <AllPlanPurchases />,
  },
  {
    path: 'settings',
    element: <SettingsPage />,
  },
];

export { authRoutes, adminRoutes, publicRoutes };
