import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createCourse,
  deleteCourse,
  getAllCourses,
  getTopCourses,
  getCourseDetails,
  getCourseSortCount,
  updateCourseDetails,
} from '../api/course';

export const courseSlice = createSlice({
  name: 'course',
  initialState: {
    loading: false,
    error: {},
    // staff
    courseList: {},
    courseCount: {},
    topCourseList: {},
    staffDropList: [],
    courseDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create staff details
    [createCourse.pending]: (state) => {
      state.loading = true;
    },
    [createCourse.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.courseList;
      const newCoursesList = jsonState?.results;

      const modifiedCourseList = {
        ...jsonState,
        results: Array.isArray(newCoursesList)
          ? [action.payload, ...newCoursesList]
          : [action.payload],
      };
      state.loading = false;
      state.courseList = modifiedCourseList;
      state.error = {};
    },
    [createCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all country
    [getAllCourses.pending]: (state) => {
      state.loading = true;
    },
    [getAllCourses.fulfilled]: (state, action) => {
      state.loading = false;
      state.courseList = action.payload;
      state.error = {};
    },
    [getAllCourses.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getCourseSortCount.pending]: (state) => {
      state.loading = true;
    },
    [getCourseSortCount.fulfilled]: (state, action) => {
      state.loading = false;
      state.courseCount = action.payload;
      state.error = {};
    },
    [getCourseSortCount.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getTopCourses.pending]: (state) => {
      state.loading = true;
    },
    [getTopCourses.fulfilled]: (state, action) => {
      state.loading = false;
      state.topCourseList = action.payload;
      state.error = {};
    },
    [getTopCourses.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getCourseDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCourseDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.courseDetails = action.payload;
      state.error = {};
    },
    [getCourseDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateCourseDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateCourseDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.courseList;
      const modifiedCourseList = {
        ...jsonState,
        results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };

      state.loading = false;
      // state.country = modifiedCourseList;
      state.CourseDetails = action.payload;
      state.courseList = modifiedCourseList;
      state.error = {};
    },
    [updateCourseDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteCourse.pending]: (state) => {
      state.loading = true;
    },
    [deleteCourse.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.courseList;
      const modifiedCourseList = {
        ...jsonState,
        results: jsonState.results?.filter((course) => course.id !== action.payload),
      };
      state.loading = false;
      state.courseList = modifiedCourseList;
      state.error = {};
    },
    [deleteCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = courseSlice.actions;

export default courseSlice.reducer;
