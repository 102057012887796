import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createQuiz = createAsyncThunk(
  'quiz/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/quiz/question/`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        const credentials = {
          page: 1,
          search: '',
          module: '',
          dispatch,
        };
        dispatch(getAllQuizes(credentials));
        dispatch(activeSnack({ type: 'success', message: 'Quiz Created Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllQuizes = createAsyncThunk(
  'quiz/list',
  async ({ page, search, dispatch, limit, module }) => {
    try {
      const response = await get(
        `/quiz/question/?page=${page ?? 1}&search=${search ?? ''}&pageSize=${
          limit || 10
        }&course_module=${module || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getQuizDetails = createAsyncThunk('quiz/single', async ({ quizId, dispatch }) => {
  try {
    const response = await get(`/quiz/question/${quizId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateQuizDetails = createAsyncThunk(
  'quiz/update',
  async ({ state, quizId, dispatch, handleClose }) => {
    try {
      const URL = `/quiz/question/${quizId}/`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const credentials = {
          page: 1,
          search: '',
          module: '',
          dispatch,
        };
        dispatch(getAllQuizes(credentials));
        dispatch(activeSnack({ type: 'success', message: 'Quiz Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteQuiz = createAsyncThunk(
  'quiz/delete',
  async ({ quizId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/quiz/question/${quizId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Quiz Deleted Successfully' }));
        return quizId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
