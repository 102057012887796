import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';

import { useTheme } from '@emotion/react';

import Grid from '@mui/material/Unstable_Grid2';
import { Stack, Typography } from '@mui/material';

import SvgColor from 'src/components/svg-color';

import TopCourses from './top-courses';
import AppWidgetSummary2 from './app-widget-summary2';
import AnalyticsWidgetSummary from './analytics-widget-summary';
// ----------------------------------------------------------------------

export default function OverviewBookingView({ dashboardData }) {
  const theme = useTheme();
  const icon = (name, iconColor) => (
    <SvgColor
      src={`/assets/icons/navbar/${name}.svg`}
      sx={{ width: 1, height: 1, color: iconColor }}
    />
  );

  // const { dashboardData, earningsChartData, salesChart } = useSelector((state) => ({
  //   dashboardData: state.dashboard.dashboardData,
  //   loading: state.dashboard.loading,
  //   earningsChartData: state.dashboard.earningsChart,
  //   salesChart: state.dashboard.salesChart,
  // }));

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <AppWidgetSummary2
            title="Total Enrollments"
            percent={dashboardData?.percentage_users}
            total={dashboardData?.total_users}
            chart={{
              colors: [theme.palette.success.light, theme.palette.success.main],
              series: [5, 18, 12, 51, 68, 11, 39, 37, 27, 20],
            }}
          />
        </Grid>

        <Grid xs={12} md={4}>
          <AppWidgetSummary2
            title="New Enrollments"
            percent={dashboardData?.new_users_percentage}
            total={dashboardData?.latest_users_count}
            chart={{
              colors: [theme.palette.info.light, theme.palette.info.main],
              series: [20, 41, 63, 33, 28, 35, 50, 46, 11, 26],
            }}
          />
        </Grid>

        <Grid xs={12} md={4}>
          <AppWidgetSummary2
            title="Total Sales"
            percent={dashboardData?.sales_percentage}
            total={dashboardData?.total_course_sale_count}
            chart={{
              colors: [theme.palette.warning.light, theme.palette.warning.main],
              series: [8, 9, 31, 8, 16, 37, 8, 33, 46, 31],
            }}
          />
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <AnalyticsWidgetSummary
            title="Courses"
            total={dashboardData?.total_course_count || 0}
            color="error"
            icon={icon('ic_file', '#FFAC82')}
          />
        </Grid>
        <Grid xs={12} sm={6} md={3}>
          <AnalyticsWidgetSummary
            title="Free"
            total={dashboardData?.free_course_count || 0}
            color="success"
            icon={icon('ic_tour', '#5BE49B')}
          />
        </Grid>

        <Grid xs={12} sm={6} md={3}>
          <AnalyticsWidgetSummary
            title="Subscription"
            total={dashboardData?.subscribable_course_count || 0}
            color="info"
            icon={icon('ic_label', '#00B8D9')}
          />
        </Grid>

        <Grid xs={12} sm={6} md={3}>
          <AnalyticsWidgetSummary
            title="Premium"
            total={dashboardData?.purchasable_course_count || 0}
            color="warning"
            icon={icon('ic_invoice', '#FFAB00')}
          />
        </Grid>
      </Grid>
      <Stack my={7}>
        <Typography variant="h6" pb={3} pl={3}>
          Latest Courses
        </Typography>
        <TopCourses />
      </Stack>
    </>
  );
}

OverviewBookingView.propTypes = {
  dashboardData: PropTypes.any,
};
