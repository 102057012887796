import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createModule,
  deleteModule,
  getAllModules,
  getModuleDetails,
  updateModuleDetails,
} from '../api/module';

export const moduleSlice = createSlice({
  name: 'module',
  initialState: {
    loading: false,
    error: {},
    // staff
    modules: {},
    moduleDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createModule.pending]: (state) => {
      state.loading = true;
    },
    [createModule.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.modules;
      const newModuleList = jsonState?.results;

      const modifiedModuleList = {
        ...jsonState,
        results: Array.isArray(newModuleList)
          ? [...newModuleList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.modules = modifiedModuleList;
      state.error = {};
    },
    [createModule.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllModules.pending]: (state) => {
      state.loading = true;
    },
    [getAllModules.fulfilled]: (state, action) => {
      state.loading = false;
      state.modules = action.payload;
      state.error = {};
    },
    [getAllModules.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getModuleDetails.pending]: (state) => {
      state.loading = true;
    },
    [getModuleDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.moduleDetails = action.payload;
      state.error = {};
    },
    [getModuleDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateModuleDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateModuleDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.modules;
      const modifiedModuleList = {
        ...jsonState,
        results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };

      state.loading = false;
      state.modules = modifiedModuleList;
      state.error = {};
    },
    [updateModuleDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteModule.pending]: (state) => {
      state.loading = true;
    },
    [deleteModule.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.modules;
      const modifiedModuleList = {
        ...jsonState,
        results: jsonState.results?.filter((mod) => mod?.id !== action.payload),
      };
      state.loading = false;
      state.modules = modifiedModuleList;
      state.error = {};
    },
    [deleteModule.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = moduleSlice.actions;

export default moduleSlice.reducer;
