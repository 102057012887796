import { useMemo } from 'react';

import { paths } from 'src/routes/routes/paths';

// import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

// ----------------------------------------------------------------------

export function useNavData() {
  // sidebar data block
  const adminSideBarData = useMemo(
    () => [
      {
        subheader: 'OVERVIEW',
        items: [
          { title: 'Dashboard', path: `${paths.dashboard.root}`, icon: icon('ic_dashboard') },
          // {
          //   title: 'Sales',
          //   path: paths.dashboard.sale,
          //   icon: icon('ic_order'),
          //   info: <Label color="warning">{counts?.totalSalesCount}</Label>,
          // },
        ],
      },
      {
        subheader: 'MANAGEMENT',
        items: [
          {
            title: 'Course',
            path: paths.dashboard.course,
            icon: icon('ic_file'),
          },
          {
            title: 'Module',
            path: paths.dashboard.module,
            icon: icon('ic_blog'),
            // icon: <Iconify icon="ic-solar:notes-bold-duotone"/>
          },
          {
            title: 'Quiz',
            path: paths.dashboard.quiz,
            icon: icon('ic_kanban'),
          },
          {
            title: 'Category',
            path: paths.dashboard.category,
            icon: icon('ic_folder'),
          },
          {
            title: 'Plan',
            path: paths.dashboard.plan,
            icon: icon('ic_label'),
          },
          {
            title: 'User',
            path: paths.dashboard.user,
            icon: icon('ic_user'),
          },
          // {
          //   title: 'Transaction',
          //   path: paths.dashboard.transaction,
          //   icon: icon('ic_invoice'),
          // },
          {
            title: 'Course Purchase',
            path: paths.dashboard.course_purchase,
            icon: icon('ic_kanban'),
          },
          {
            title: 'Plan Purchase',
            path: paths.dashboard.plan_purchase,
            icon: icon('ic_job'),
          },
        ],
      },
    ],
    []
  );

  return adminSideBarData;
}

// export function useNavData() {
//   const data = useMemo(() => adminSideBarData, []);

//   return data;
// }

// const ICONS = {
//   job: icon('ic_job'),
//   blog: icon('ic_blog'),
//   chat: icon('ic_chat'),
//   mail: icon('ic_mail'),
//   user: icon('ic_user'),
//   file: icon('ic_file'),
//   lock: icon('ic_lock'),
//   tour: icon('ic_tour'),
//   order: icon('ic_order'),
//   label: icon('ic_label'),
//   blank: icon('ic_blank'),
//   kanban: icon('ic_kanban'),
//   folder: icon('ic_folder'),
//   banking: icon('ic_banking'),
//   booking: icon('ic_booking'),
//   invoice: icon('ic_invoice'),
//   product: icon('ic_product'),
//   calendar: icon('ic_calendar'),
//   disabled: icon('ic_disabled'),
//   external: icon('ic_external'),
//   menuItem: icon('ic_menu_item'),
//   ecommerce: icon('ic_ecommerce'),
//   analytics: icon('ic_analytics'),
//   dashboard: icon('ic_dashboard'),
// };
