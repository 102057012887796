import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createModuleItem,
  deleteModuleItem,
  getAllModuleItems,
  getModuleItemDetails,
  updateModuleItemDetails,
} from '../api/moduleItem';

export const moduleItemSlice = createSlice({
  name: 'moduleItem',
  initialState: {
    loading: false,
    error: {},
    // staff
    moduleItems: {},
    moduleItemDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createModuleItem.pending]: (state) => {
      state.loading = true;
    },
    [createModuleItem.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.moduleItems;
      const newModuleItemList = jsonState?.results;

      const modifiedModuleItemList = {
        ...jsonState,
        results: Array.isArray(newModuleItemList)
          ? [...newModuleItemList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.moduleItems = modifiedModuleItemList;
      state.error = {};
    },
    [createModuleItem.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllModuleItems.pending]: (state) => {
      state.loading = true;
    },
    [getAllModuleItems.fulfilled]: (state, action) => {
      state.loading = false;
      state.moduleItems = action.payload;
      state.error = {};
    },
    [getAllModuleItems.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getModuleItemDetails.pending]: (state) => {
      state.loading = true;
    },
    [getModuleItemDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.moduleItemDetails = action.payload;
      state.error = {};
    },
    [getModuleItemDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateModuleItemDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateModuleItemDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.moduleItems;
      const modifiedModuleItemList = {
        ...jsonState,
        results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };

      state.loading = false;
      state.moduleItems = modifiedModuleItemList;
      state.error = {};
    },
    [updateModuleItemDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteModuleItem.pending]: (state) => {
      state.loading = true;
    },
    [deleteModuleItem.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.moduleItems;
      const modifiedModuleItemList = {
        ...jsonState,
        results: jsonState.results?.filter((mod) => mod?.id !== action.payload),
      };
      state.loading = false;
      state.moduleItems = modifiedModuleItemList;
      state.error = {};
    },
    [deleteModuleItem.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = moduleItemSlice.actions;

export default moduleItemSlice.reducer;
