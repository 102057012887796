import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createCourseCategory = createAsyncThunk(
  'course-category/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/course/course-category/`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Course Category Created Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCourseCategories = createAsyncThunk(
  'course-category/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/course/course-category/?page=${page ?? 1}&search=${search ?? ''}&pageSize=${limit || 10}`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getCourseCategoryDetails = createAsyncThunk(
  'course-category/single',
  async ({ categoryId, dispatch }) => {
    try {
      const response = await get(`/course/course-category/${categoryId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateCourseCategoryDetails = createAsyncThunk(
  'course-category/update',
  async ({ state, categoryId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/course/course-category/${categoryId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Course Category Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteCourseCategory = createAsyncThunk(
  'course-category/delete',
  async ({ categoryId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/course/course-category/${categoryId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Course Category Deleted Successfully' }));
        return categoryId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
