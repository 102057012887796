import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createQuiz,
  deleteQuiz,
  getAllQuizes,
  getQuizDetails,
  updateQuizDetails,
} from '../api/quiz';

export const quizSlice = createSlice({
  name: 'quiz',
  initialState: {
    loading: false,
    error: {},
    // staff
    quizes: {},
    quizDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createQuiz.pending]: (state) => {
      state.loading = true;
    },
    [createQuiz.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.quizes;
      const newQuizList = jsonState?.results;

      const modifiedQuizList = {
        ...jsonState,
        results: Array.isArray(newQuizList) ? [...newQuizList, action.payload] : [action.payload],
      };
      state.loading = false;
      state.quizes = modifiedQuizList;
      state.error = {};
    },
    [createQuiz.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllQuizes.pending]: (state) => {
      state.loading = true;
    },
    [getAllQuizes.fulfilled]: (state, action) => {
      state.loading = false;
      state.quizes = action.payload;
      state.error = {};
    },
    [getAllQuizes.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getQuizDetails.pending]: (state) => {
      state.loading = true;
    },
    [getQuizDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.quizDetails = action.payload;
      state.error = {};
    },
    [getQuizDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateQuizDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateQuizDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.quizes;
      const modifiedQuizList = {
        ...jsonState,
        results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };

      state.loading = false;
      state.quizes = modifiedQuizList;
      state.error = {};
    },
    [updateQuizDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteQuiz.pending]: (state) => {
      state.loading = true;
    },
    [deleteQuiz.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.quizes;
      const modifiedQuizList = {
        ...jsonState,
        results: jsonState.results?.filter((mod) => mod?.id !== action.payload),
      };
      state.loading = false;
      state.quizes = modifiedQuizList;
      state.error = {};
    },
    [deleteQuiz.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = quizSlice.actions;

export default quizSlice.reducer;
