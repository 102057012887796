import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createCourseCategory,
  deleteCourseCategory,
  getAllCourseCategories,
  getCourseCategoryDetails,
  updateCourseCategoryDetails,
} from '../api/courseCategory';

export const courseCategorySlice = createSlice({
  name: 'courseCategory',
  initialState: {
    loading: false,
    error: {},
    // staff
    courseCategoryList: {},
    staffDropList: [],
    CourseCategoryDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create staff details
    [createCourseCategory.pending]: (state) => {
      state.loading = true;
    },
    [createCourseCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.courseCategoryList;
      const newCategoryList = jsonState?.results;

      const modifiedCourseList = {
        ...jsonState,
        results: Array.isArray(newCategoryList)
          ? [action.payload, ...newCategoryList]
          : [action.payload],
      };
      state.loading = false;
      state.courseCategoryList = modifiedCourseList;
      state.error = {};
    },
    [createCourseCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all country
    [getAllCourseCategories.pending]: (state) => {
      state.loading = true;
    },
    [getAllCourseCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.courseCategoryList = action.payload;
      state.error = {};
    },
    [getAllCourseCategories.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getCourseCategoryDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCourseCategoryDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.CourseCategoryDetails = action.payload;
      state.error = {};
    },
    [getCourseCategoryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateCourseCategoryDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateCourseCategoryDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.courseCategoryList;
      const modifiedCategoryList = {
        ...jsonState,
        results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };

      state.loading = false;
      // state.country = modifiedCourseList;
      state.courseCategoryList = modifiedCategoryList;
      state.error = {};
    },
    [updateCourseCategoryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteCourseCategory.pending]: (state) => {
      state.loading = true;
    },
    [deleteCourseCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.courseCategoryList;
      const modifiedCategoryList = {
        ...jsonState,
        results: jsonState.results?.filter((category) => category?.id !== action.payload),
      };
      state.loading = false;
      state.courseCategoryList = modifiedCategoryList;
      state.error = {};
    },
    [deleteCourseCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = courseCategorySlice.actions;

export default courseCategorySlice.reducer;
