import { configureStore } from '@reduxjs/toolkit';

import appReducer from './app';
import authReducer from './auth';
import UserReducer from './user';
import QuizReducer from './quiz';
import commonReducer from './common';
import CourseReducer from './course';
import ModuleReducer from './module';
import ModuleItem from './moduleItem';
import CountryReducer from './country';
import settingsReducer from './settings';
import dashboardReducer from './dashboard';
import PlanReducer from './subscriptionPlan';
import PlanPurchaseReducer from './planPurchase';
import CourseCategoryReducer from './courseCategory';
import CoursePurchaseReducer from './coursePurchase';

export default configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    country: CountryReducer,
    course: CourseReducer,
    quiz: QuizReducer,
    courseCategory: CourseCategoryReducer,
    plan: PlanReducer,
    user: UserReducer,
    coursePurchase: CoursePurchaseReducer,
    planPurchase: PlanPurchaseReducer,
    module: ModuleReducer,
    moduleItem: ModuleItem,
    settings: settingsReducer,
  },
});
