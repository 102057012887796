import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createModuleItem = createAsyncThunk(
  'course-module-item/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/course/course-module-item/`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'ModuleItem Created Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllModuleItems = createAsyncThunk(
  'course-module-item/list',
  async ({ page, search, dispatch, limit, course_module }) => {
    try {
      const response = await get(
        `/course/course-module-item/?page=${page ?? 1}&search=${search ?? ''}&pageSize=${
          limit || 10
        }&course_module=${course_module || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getModuleItemDetails = createAsyncThunk(
  'course-module-item/single',
  async ({ moduleItemId, dispatch }) => {
    try {
      const response = await get(`/course/course-module-item/${moduleItemId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateModuleItemDetails = createAsyncThunk(
  'course-module-item/update',
  async ({ state, moduleItemId, dispatch, handleClose }) => {
    try {
      const URL = `/course/course-module-item/${moduleItemId}/`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'ModuleItem Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteModuleItem = createAsyncThunk(
  'course-module-item/delete',
  async ({ moduleItemId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/course/course-module-item/${moduleItemId}/`);
      if (response) {
        if (handleCloseDeleteDialog) {
          handleCloseDeleteDialog();
        }
        dispatch(
          activeSnack({
            type: 'success',
            message: response?.message || 'ModuleItem Deleted Successfully',
          })
        );
        return moduleItemId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const fileUpload = createAsyncThunk(
  'course-module-item/file-upload',
  async ({ state, dispatch }) => {
    try {
      const URL = `/course/files/`;
      const response = await post(URL, state);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'ModuleItem Created Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
