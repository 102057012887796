import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createCourse = createAsyncThunk(
  'course/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/course/course/`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Course Created Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCourses = createAsyncThunk(
  'course/list',
  async ({ page, search, dispatch, limit, sort, latest }) => {
    const url = `/course/course/?page=${page ?? 1}&search=${search ?? ''}&pageSize=${
      limit || 10
    }&sort=${sort || ''}`;
    const latestUrl = `/course/course/?latest=${latest && latest}`;
    try {
      const response = await get(latest ? latestUrl : url);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getCourseSortCount = createAsyncThunk('course-count/single', async ({ dispatch }) => {
  try {
    const response = await get(`/course/count/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getTopCourses = createAsyncThunk(
  'top-course/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/course/course-category/?page=${page ?? 1}&search=${search ?? ''}&pageSize=${
          limit || 10
        }&with_course=true`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getCourseDetails = createAsyncThunk(
  'course/single',
  async ({ courseId, dispatch }) => {
    try {
      const response = await get(`/course/course/${courseId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateCourseDetails = createAsyncThunk(
  'course/update',
  async ({ state, courseId, dispatch, handleClose }) => {
    try {
      const URL = `/course/course/${courseId}/`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const credentials = {
          page: 1,
          limit: 10,
          search: '',
          dispatch,
        };
        dispatch(getAllCourses(credentials));
        dispatch(activeSnack({ type: 'success', message: 'Course Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteCourse = createAsyncThunk(
  'course/delete',
  async ({ courseId, dispatch, handleCloseDeleteDialog, navigate }) => {
    try {
      const response = await del(`/course/course/${courseId}/`);
      if (response) {
        handleCloseDeleteDialog();
        if (navigate) {
          navigate(paths.dashboard.course);
        }
        dispatch(activeSnack({ type: 'success', message: 'Course deleted successfully' }));
        return courseId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
