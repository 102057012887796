import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createModule = createAsyncThunk(
  'course-module/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/course/course-module/`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(getAllModules({ dispatch }));
        dispatch(activeSnack({ type: 'success', message: 'Module Created Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllModules = createAsyncThunk(
  'course-module/list',
  async ({ page, search, dispatch, limit, course }) => {
    try {
      const response = await get(
        `/course/course-module/?page=${page ?? 1}&search=${search ?? ''}&pageSize=${
          limit || 10
        }&course=${course || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getModuleDetails = createAsyncThunk(
  'course-module/single',
  async ({ moduleId, dispatch }) => {
    try {
      const response = await get(`/course/course-module/${moduleId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateModuleDetails = createAsyncThunk(
  'course-module/update',
  async ({ state, moduleId, dispatch, handleClose }) => {
    try {
      const URL = `/course/course-module/${moduleId}/`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Module Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteModule = createAsyncThunk(
  'course-module/delete',
  async ({ moduleId, dispatch, setOpenDeleteConfirmDialog }) => {
    try {
      const response = await del(`/course/course-module/${moduleId}/`);
      if (response) {
        if (setOpenDeleteConfirmDialog) {
          setOpenDeleteConfirmDialog();
        }
        dispatch(getAllModules({ dispatch }));
        dispatch(activeSnack({ type: 'success', message: 'Module Deleted Successfully' }));
        return moduleId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
