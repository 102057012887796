import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Paper, TableRow, TableCell, CircularProgress } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { getAllCourses } from 'src/server/api/course';

import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  // TablePaginationCustom,
} from 'src/components/table';

import TopCourseTableRow from 'src/sections/Course/TopCourseTableRow';

// ----------------------------------------------------------------------

export default function TopCourses() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const router = useRouter();
  const table = useTable({ defaultOrderBy: 'createdAt' });
  const confirm = useBoolean();

  const { loading, courseList } = useSelector((state) => ({
    loading: state.user.loading,
    courseList: state.course.courseList,
    plans: state.plan.plans,
  }));

  const [tableData, setTableData] = useState([]);
  // const totalPage = planPurchaseList?.count;

  const TABLE_HEAD = [
    { id: 'course', label: 'Course' },
    { id: 'author', label: 'Author' },
    { id: 'course_type', label: 'Course Type' },
    { id: 'total_sales', label: 'Total Sales' },
  ];

  useEffect(() => {
    const credentials = {
      // page: table.page + 1,
      // search: '',
      // limit: table.rowsPerPage,
      dispatch,
      latest: true,
    };
    dispatch(getAllCourses(credentials));
  }, [dispatch]);

  useEffect(() => {
    setTableData(courseList?.results);
  }, [courseList]);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  return (
    <>
      <Card>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected?.length}
            rowCount={tableData?.length}
            onSelectAllRows={(checked) => {
              table.onSelectAllRows(
                checked,
                tableData.map((row) => row.id)
              );
            }}
          />
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length}
                numSelected={table.selected?.length}
                onSort={table.onSort}
              />

              {loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <CircularProgress color="inherit" />
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              <TableBody>
                {(tableData || []).map((row, index) => (
                  <TopCourseTableRow
                    index={index}
                    page={table.page + 1}
                    rowsPerPage={table.rowsPerPage || 5}
                    key={row.id}
                    row={row}
                    selected={table?.selected?.includes(row.id)}
                    onSelectRow={() => table.onSelectRow(row.id)}
                    onViewRow={() => handleViewRow(row.id)}
                    onEditRow={() => handleEditRow(row.id)}
                    onDeleteRow={() => handleDeleteRow(row.id)}
                    loading={loading}
                    colSpan={TABLE_HEAD?.length}
                  />
                ))}
              </TableBody>

              {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

              <TableNoData notFound={notFound} />
            </Table>
          </Scrollbar>
        </TableContainer>

        {/* <TablePaginationCustom
          count={totalPage || 0}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        /> */}
      </Card>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}
