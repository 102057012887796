import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { useSetToken } from 'src/hooks/useHandleSessions';

import { get, put, post } from './http';
import { activeSnack } from '../store/common';

export const adminLoginApi = createAsyncThunk(
  'auth/login',
  async ({ state, dispatch, setVerifyPhone }) => {
    try {
      const response = await post('/users/login-phone-otp/', state);

      dispatch(activeSnack({ type: 'success', message: `OTP Sent to ${state?.phone}` }));
      if (response) {
        if (response?.message === 'OTP sent successfully') {
          setVerifyPhone(true);
        }
        // useSetToken(response?.token);
        // useSetRole(response?.role);
        // navigate(paths.dashboard.root, { replace: true });
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const verifyOtpApi = createAsyncThunk('auth/otp', async ({ state, dispatch, navigate }) => {
  try {
    const response = await post('/users/verify-phone-otp/', state);

    dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated ' }));
    if (response) {
      useSetToken(response?.token);
      // useSetRole(response?.role);
      navigate(paths.dashboard.root);
      return response;
    }

    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const logoutApi = createAsyncThunk('auth/logout', async ({ dispatch, navigate }) => {
  try {
    const response = await get('/logout');
    if (response) {
      sessionStorage.clear();
      dispatch(activeSnack({ type: 'success', message: 'logged out successfully' }));
      navigate(paths.public.landing, { replace: true });
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    sessionStorage.clear();
    navigate(paths.public.login, { replace: true });
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getUserProfile = createAsyncThunk('user/profile', async ({ enqueueSnackbar }) => {
  try {
    const response = await get(`/user-account`);
    if (response?.id) {
      return response;
    }
    return enqueueSnackbar('something went wrong', { variant: 'error' });
  } catch (error) {
    enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    throw error?.response?.data?.message;
  }
});

export const updateUserProfile = createAsyncThunk(
  'user/profile/update',
  async ({ enqueueSnackbar, state }) => {
    try {
      const response = await put(`/user/update`, state);
      if (response?._id) {
        enqueueSnackbar('Profile Updated Successfully');
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
