import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from './http';
import { activeSnack } from '../store/common';

export const getAllCoursePurchases = createAsyncThunk(
  'course-purchase/list',
  async ({ page, search, dispatch, limit, course, sortDate }) => {
    try {
      const response = await get(
        `/course/course-purchase/?page=${page ?? 1}&search=${search ?? ''}&pageSize=${
          limit || 10
        }&course=${course || ''}&start_date=${sortDate?.start_date || ''}&end_date=${
          sortDate?.end_date || ''
        }`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
