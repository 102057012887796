import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createPlan,
  deletePlan,
  getAllPlans,
  getPlanDetails,
  updatePlanDetails,
} from '../api/subscriptionPlan';

export const planSlice = createSlice({
  name: 'plan',
  initialState: {
    loading: false,
    error: {},
    // staff
    plans: {},
    planDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createPlan.pending]: (state) => {
      state.loading = true;
    },
    [createPlan.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.plans;
      const newPlanList = jsonState?.results;

      const modifiedPlanList = {
        ...jsonState,
        results: Array.isArray(newPlanList) ? [...newPlanList, action.payload] : [action.payload],
      };
      state.loading = false;
      state.plans = modifiedPlanList;
      state.error = {};
    },
    [createPlan.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllPlans.pending]: (state) => {
      state.loading = true;
    },
    [getAllPlans.fulfilled]: (state, action) => {
      state.loading = false;
      state.plans = action.payload;
      state.error = {};
    },
    [getAllPlans.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getPlanDetails.pending]: (state) => {
      state.loading = true;
    },
    [getPlanDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.planDetails = action.payload;
      state.error = {};
    },
    [getPlanDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updatePlanDetails.pending]: (state) => {
      state.loading = true;
    },
    [updatePlanDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.plans;
      const modifiedPlanList = {
        ...jsonState,
        results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };

      state.loading = false;
      state.plans = modifiedPlanList;
      state.error = {};
    },
    [updatePlanDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deletePlan.pending]: (state) => {
      state.loading = true;
    },
    [deletePlan.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.plans;
      const modifiedPlanList = {
        ...jsonState,
        results: jsonState.results?.filter((plan) => plan?.id !== action.payload),
      };
      state.loading = false;
      state.plans = modifiedPlanList;
      state.error = {};
    },
    [deletePlan.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = planSlice.actions;

export default planSlice.reducer;
