import { createSlice } from '@reduxjs/toolkit';

import { getAllCoursePurchases } from '../api/coursePurchase';

export const coursePurchaseSlice = createSlice({
  name: 'coursePurchase',
  initialState: {
    loading: false,
    error: {},
    // staff
    coursePurchaseList: {},
    staffDropList: [],
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [getAllCoursePurchases.pending]: (state) => {
      state.loading = true;
    },
    [getAllCoursePurchases.fulfilled]: (state, action) => {
      state.loading = false;
      state.coursePurchaseList = action.payload;
      state.error = {};
    },
    [getAllCoursePurchases.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = coursePurchaseSlice.actions;

export default coursePurchaseSlice.reducer;
